<template>
  <language-switcher v-slot="{ links }">
    <b-dropdown-item
      v-for="link in links"
      :key="link.langIndex"
      has-link
      aria-role="menuitem"
    >
      <router-link
        v-if="link.langIndex !== $i18n.locale"
        v-slot="{ navigate, href }"
        :key="link.langIndex"
        :to="link.url"
        custom
      >
        <a
          :href="href"
          title="link.langName"
          @click="updateLanguage(link.langIndex, navigate, $event)"
        >
          {{ link.langIndex.toUpperCase() }}
        </a>
      </router-link>
    </b-dropdown-item>
  </language-switcher>
</template>

<script>
export default {
  name: 'UserLanguageSwitcher',
  methods: {
    async updateLanguage(languageCode, navigate, event) {
      navigate(event)
      await this.$store.dispatch('Users/updateUserProfile', {
        language_code: languageCode
      })
    }
  }
}
</script>
