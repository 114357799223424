<template>
  <div
    class="participant-logo"
    :style="{ 'color': userFontColor, 'background-color': userBackgroundColor }"
  >
    {{ userData.first_name | firstLetter }}{{ userData.last_name | firstLetter }}
  </div>
</template>
<script>
export default {
  name: 'AvatarLetter',
  filters: {
    firstLetter(value) {
      if (!value) return ''

      return value.charAt(0)
    }
  },
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  computed: {
    userColor() {
      const hash = this.hashCode(this.userData.first_name + this.userData.last_name)
      const color = this.intToRGB(hash)

      return color
    },
    userFontColor() {
      return `#${this.userColor}cc`
    },
    userBackgroundColor() {
      return `#${this.userColor}22`
    }
  },
  methods: {
    hashCode(str) {
      let hash = 0

      for (let i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash) // eslint-disable-line no-bitwise
      }

      return hash
    },
    intToRGB(i) {
      const c = (i & 0x00FFFFFF) // eslint-disable-line no-bitwise
        .toString(16)
        .toUpperCase()

      return '00000'.substring(0, 6 - c.length) + c
    }
  }
}
</script>
<style lang="scss" scoped>
.participant-logo {
  width: 50px;
  height: 50px;
  background-color: #ffeaea;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #f89696;
}
</style>
