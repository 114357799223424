<template>
  <div class="mobile-menu">
    <ul>
      <li class="lead">
        Menú
      </li>
      <li>
        <router-link
          :to="{ name: 'proyectos' }"
          @click.native="closeMobileMenu"
        >
          Proyectos
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'profile' }"
          @click.native="closeMobileMenu"
        >
          Mi Perfil
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'logout' }"
          @click.native="closeMobileMenu"
        >
          Cerrar Sesión
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TheMobileMenu',
  computed: {
    ...mapGetters('Ui', ['isMobileMenuOpen'])
  },
  methods: {
    closeMobileMenu() {
      this.$store.commit('Ui/toggleMobileMenu')
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-menu {
  display: none;

  @include respond(mobile) {
    position: absolute;
    top: 0;
    background-color: $white;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 120px;

    ul {
      li {
        font-size: 24px;
        line-height: 30px;
        color: $black;
        margin-bottom: 49px;

        &.lead {
          font-weight: 600;
        }

        &:last-child {
          margin-bottom: 0px;
        }

        a {
          color: $black;

          &.router-link-exact-active {
            color: $blue;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
