<template>
  <div>
    <the-mobile-menu :class="{ open: isMobileMenuOpen }" />
    <nav
      :class="navbarClasses"
      role="navigation"
      aria-label="main navigation"
    >
      <div
        v-if="returnLink"
        class="return-link"
      >
        <localized-router-link
          v-slot="{ href, navigate }"
          :to="returnLink.link"
          custom
        >
          <a
            :href="href"
            @click="navigate"
          >
            {{ returnLink.title }}
          </a>
        </localized-router-link>
      </div>
      <div class="navbar-brand">
        <localized-router-link
          class="navbar-item"
          to="/"
        >
          <img
            src="../assets/logo.svg"
            width="112"
            height="28"
          >
        </localized-router-link>
        <div class="open-menu">
          <div
            class="hamburger"
            :class="{ active: isMobileMenuOpen }"
            @click="toggleMobileMenu"
          >
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
      <!-- Prevent display if userData not defined -->
      <div
        v-if="!anonymous && userData"
        class="navbar-menu"
      >
        <div class="navbar-start" />

        <div
          class="navbar-end"
        >
          <b-dropdown
            v-if="displayLanguageSelector"
            position="is-bottom-left"
            append-to-body
            aria-role="menu"
            trap-focus
          >
            <template #trigger>
              <a
                class="navbar-item"
                role="button"
                title="Español"
              >
                <span>{{ $i18n.locale.toUpperCase() }}</span>
                <b-icon icon="angle-down" />
              </a>
            </template>
            <user-language-switcher />
          </b-dropdown>
          <localized-router-link
            class="navbar-item navbar-tabs"
            :to="{ name: 'proyectos' }"
          >
            Proyectos
          </localized-router-link>

          <div class="navbar-item has-dropdown is-hoverable navbar-item-profile">
            <a class="navbar-link">
              <avatar-letter-navbar
                :user-data="userData"
                class="avatar-navbar"
              />
            </a>
            <div
              class="navbar-dropdown is-right"
            >
              <localized-router-link
                :to="{ name: 'profile' }"
                class="navbar-item navbar-subitem"
              >
                <div class="link-profile">
                  <div>
                    <avatar-letter-navbar :user-data="userData" />
                  </div>
                  <div>
                    <p>
                      <strong>{{ userFullName }}</strong>
                    </p>
                    <p>{{ userData.email }}</p>
                  </div>
                </div>
              </localized-router-link>

              <hr class="navbar-divider">

              <localized-router-link
                :to="{ name: 'logout' }"
                class="navbar-item navbar-subitem"
              >
                <div class="link-profile">
                  <div>
                    <img
                      src="../assets/navbar/navbar-icon-logout.svg"
                      class="icon-logout"
                    >
                  </div>
                  <div>
                    <p class="logout">
                      Cerrar Sesión
                    </p>
                  </div>
                </div>
              </localized-router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TheMobileMenu from './TheMobileMenu.vue'
import AvatarLetterNavbar from './user-profile/AvatarLetter.vue'
import UserLanguageSwitcher from './UserLanguageSwitcher.vue'

export default {
  name: 'TheNavbar',
  components: {
    AvatarLetterNavbar,
    TheMobileMenu,
    UserLanguageSwitcher
  },
  props: {
    anonymous: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    },
    returnLink: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState('Users', ['userData']),
    ...mapGetters('Ui', ['isMobileMenuOpen']),
    ...mapGetters('dataExports', ['pendingExports']),

    navbarClasses() {
      const classes = ['navbar', 'is-fixed-top']

      if (this.centered) classes.push('centered')

      return classes
    },
    userFullName() {
      return !this.userData || this.anonymous ? '' : `${this.userData.first_name} ${this.userData.last_name}`
    },
    displayLanguageSelector() {
      return Object.keys(this.$i18n.messages).length > 1
    }
  },
  created() {
    if (!this.anonymous) {
      this.$store.dispatch('Users/getUser') // Dispatch action 'getUser' from the Vuex Store
    }
  },
  methods: {
    toggleMobileMenu() {
      this.$store.commit('Ui/toggleMobileMenu')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.mobile-menu {
  display: none;
  opacity: 0;
  z-index: -1;

  @include respond(mobile) {
    display: flex;
    opacity: 0;
    transition: all 0.2s ease-out;
    z-index: -1;

    &.open {
      opacity: 1;
      transition: all 0.2s ease-in;
      z-index: 9999;
    }
  }
}

.navbar {
  min-height: 60px;
  box-shadow: 0px 2px 4px 0px rgb(81 107 152 / 0.16);
  align-items: center;
  user-select: none;
  z-index: 1000;

  @include respond(mobile-tablet) {
    z-index: 10000;

    .return-link {
      display: none;
    }
  }

  @include respond(desktop-up) {
    &.centered {
      .navbar-brand {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: auto;

        .navbar-item {
          padding: 0 25px;
          width: auto;
        }
      }
    }

    .return-link {
      height: 60px;
      border-right: 1px solid #E1E1E1;
      max-width: 300px;

      a {
        display: block;
        line-height: 60px;
        background: url('../assets/arrow-left-blue.svg');
        background-position: 13px center;
        background-repeat: no-repeat;
        padding: 0 27px 0 43px;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .navbar-brand {
    width: 246px;
    height: 60px;

    @include respond(mobile) {
      width: 100%;
      position: relative;
    }

    a {
      width: 100%;
      padding: 0.5rem 1.5rem;

      @include respond(mobile) {
        width: auto;
        padding-left: 16px;

        img {
          height: 21px;
        }
      }

      @include respond(tablet-up) {
        img {
          height: 30px;
        }
      }

      img {
        width: auto;
        display: block;
        max-height: unset;
      }

      &.navbar-burger {
        height: 60px;

        &:hover,
        &:focus,
        &:active {
          background: transparent;
        }

        span {
          width: 24px;
          height: 2px;
          left: calc(50% - 22px);

          &:nth-child(1) {
            top: calc(50% - 2px);
          }

          &:nth-child(2) {
            top: calc(50% + 5px);
          }
        }
      }
    }

    .open-menu {
      display: none;

      @include respond(mobile-tablet) {
        display: block;
        position: absolute;
        z-index: 10000;
        right: 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        padding-top: 2px;

        .hamburger {
          display: flex;
          align-items: center;
          flex-direction: column;
          cursor: pointer;

          span {
            background-color: $black;
            width: 33px;
            height: 3px;
            border-radius: 3px;
            margin: 4px 0;
            display: block;
            transition: all 0.2s ease;
            transform-origin: 0 0;

            &:nth-child(2) {
              display: none;
            }
          }

          &.active {
            span {
              &:nth-child(1) {
                transform: rotate(45deg) translate(-7px, -2px);
                transform-origin: 2px 12px;
              }

              &:nth-child(2) {
                transform: scaleX(0);
              }

              &:nth-child(3) {
                transform: rotate(-45deg) translate(-8px, 0);
                transform-origin: 7px -9px;
              }
            }
          }
        }
      }
    }
  }

  .navbar-menu {
    height: 60px;
    padding-right: 1.5rem;

    .navbar-end {
      .navbar-item {
        transition: all 0.2s ease-in-out;
        color: $gray-dark;

        &:focus {
          background-color: transparent;
          color: $gray-dark;
          outline: none;
        }

        &:hover {
          background-color: transparent;
          color: #adadad;
          transition: all 0.2s ease-in-out;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          font-size: 16px;
          line-height: 20px;

          &.router-link-exact-active {
            color: $blue;
            font-weight: 600;
            border-bottom: 2px solid $blue;
          }
        }

        &.has-dropdown {
          &:hover {
            .navbar-link {
              background-color: transparent;
            }
          }
        }

        .btn-new-project {
          background-color: $green;
          height: 35px;
          width: 130px;
          margin-left: 15px;
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
          transition: all 0.2s ease-in-out;

          &:hover {
            background-color: $green-hover;
            transition: all 0.2s ease-in-out;
          }
        }

        &.navbar-tabs {
          width: 114px;
          display: inline-grid;
          padding: 0px;
          margin: 0px;
        }

        &.navbar-item-notifications {
          margin-right: 30px;
          margin-left: 30px;

          img {
            width: 24px;
            padding-top: 5px;
            max-height: none;
          }
        }

        &.navbar-item-profile {
          transition: all 0.2s ease-in-out;
          margin-left: 30px;

          &:hover {
            background-color: transparent;
            color: #adadad;
            transition: all 0.2s ease-in-out;
          }

          .navbar-link {
            display: inline-block;
            padding: 12px 0px;

            .avatar-navbar {
              width: 36px;
              height: 36px;
              font-size: 13px;
            }

            &:hover {
              background-color: transparent;
            }

            &:after {
              display: none;
            }

            img {
              display: block;
              border-radius: 50px;
              max-height: none;
            }
          }

          .navbar-dropdown {
            min-width: 185px;

            .navbar-subitem {
              font-size: 15px;
              margin-left: 0px;
              padding: 0.3rem 0.8rem;

              &:hover {
                .link-profile {
                  div {
                    p {
                      color: #adadad !important;
                      transition: all 0.2s ease-in-out;
                    }
                  }
                }
              }

              &.router-link-active {
                color: $gray-dark;
                border: none;
                font-weight: 500;
              }

              .link-profile {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                div {
                  &:first-child {
                    width: 36px;
                    height: 36px;
                    margin-right: 8px;
                    font-size: 13px;
                  }

                  img {
                    width: 34px;
                    height: 34px;
                    max-height: unset;
                    border-radius: 50px;

                    &.icon-logout {
                      border-radius: 0px;
                    }
                  }

                  p {
                    font-size: 12px;
                    line-height: 16px;
                    text-align: left;
                    transition: all 0.2s ease-in-out;

                    strong {
                      font-size: 14px;
                    }

                    &.logout {
                      font-size: 16px;
                      font-weight: 600;
                      color: $black;
                      transition: all 0.2s ease-in-out;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media print {
  .navbar {
    box-shadow: none;

    .navbar-brand {
      width: auto;
      justify-content: center;

      a.navbar-item {
        width: auto;
        display: flex;
        align-items: center;

        img {
          height: 26px;
        }
      }

      .open-menu {
        display: none;
      }
    }
  }
}
</style>
